// source code js
App.surveys = {
  type: {
    survey: {
      two: "two_options",
      three: "three_options"
    },
    delivery: {
      custom: "custom",
      periodic: "periodic"
    },
    destination: {
      all: "1",
      users: "2",
      group: "3"
    },
    delivery_periodic: {
      every_day: "2",
      specified_day: "3"
    }
  },

  index: () => {
    $('.cancel-survey').on('click', (e) => {
      if (confirm('キャンセルしますか？') == true) {
        $this = $(e.currentTarget);
        $.ajax({
          url: '/xhr/surveys/cancel',
          method: 'POST',
          data: {'id': $this.data('id')}
        });
      }
    });

    App.surveys.set_tooltip('#list_surveys');
  },

  new: () => {
    App.surveys.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.surveys.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
  },

  create: () => {
    App.surveys.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.surveys.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
    App.surveys.update_checked_user_action_create();
    App.surveys.update_checked_group_action_create();
    App.surveys.sort_index_label();
  },

  edit: () => {
    // delivery_type
    var delivery_type = $('#delivery_type_option_2').data('delivery-periodic');
    if (Array.isArray(delivery_type)) {
      $(`input[name="schedules[delivery_periodic]"][value="3"]`).prop('checked', true);
      $('#day_of_week').val(delivery_type).trigger('change');
    } else {
      $(`input[name="schedules[delivery_periodic]"][value="${delivery_type}"]`).prop('checked', true);
    }
    // destination_type
    var destination_type = $('#div_destination_type').data('destination');
    $(`input[name="destination_type"][value="${destination_type}"]`).prop('checked', true);

    App.surveys.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.surveys.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
    App.surveys.sort_index_label();
  },

  update: () => {
    // delivery_type
    var delivery_type = $('#delivery_type_option_2').data('delivery-periodic');
    if (Array.isArray(delivery_type)) {
      $(`input[name="schedules[delivery_periodic]"][value="3"]`).prop('checked', true);
      $('#day_of_week').val(delivery_type).trigger('change');
    } else {
      $(`input[name="schedules[delivery_periodic]"][value="${delivery_type}"]`).prop('checked', true);
    }
    // destination_type
    var destination_type = $('#div_destination_type').data('destination');
    $(`input[name="destination_type"][value="${destination_type}"]`).prop('checked', true);

    App.surveys.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.surveys.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
    App.surveys.update_checked_user_action_create();
    App.surveys.update_checked_group_action_create();
    App.surveys.sort_index_label();
  },

  histories: () => {
    var list_receiver = $('#list_receiver');
    list_receiver.on('show.bs.modal', e => {
      var button = $(e.relatedTarget);
      var users = button.data('users');
      var $list_users = list_receiver.find('#list_users');
      $list_users.html(users);
    })
    .on('hide.bs.modal', () => {
      var $list_users = list_receiver.find('#list_users');
      $list_users.html('');
    });
  },

  check_to_hide_or_show_add_link: () => {
    if ($('#delivery_type_option_1 .nested-fields:visible').length == 4) {
      $('#delivery_type_option_1 #add_schedule').hide();
    } else {
      $('#delivery_type_option_1 #add_schedule').show();
    }
  },

  update_after_insert: element => {
    var count = $('#delivery_type_option_1 .nested-fields:visible').length;
    element.find('label').html(`${count}回目`);
  },

  update_after_remove: () => {
    $('#delivery_type_option_1 .nested-fields:visible').each((index, elm) => {
      $(elm).find('label').html(`${index + 1}回目`);
    });
  },

  update_after_change_date: () => {
    var arr = [];
    var nested_fields = $('#delivery_type_option_1 .nested-fields:visible');
    nested_fields.each((_index, element) => {
      var obj = {};
      value = $(element).find('.date-value').val();
      if (value === '') {
        timestamp = '';
      } else {
        date = new Date($.trim(value));
        timestamp = date.getTime();
      }

      obj.value = value;
      obj.time = timestamp;

      arr.push(obj);
    });

    var sorted = arr.sort((a, b) => {
      if (a.time == '') return 1;
      if (b.time == '') return -1;
      return a.time - b.time;
    });

    $.each(sorted, (index, element) => {
      $(nested_fields.get(index)).find('.date-value').val(element.value);
    });
  },

  init: () => {
    const TYPE = App.surveys.type;
    App.surveys.init_common('survey');
    var $survey_type = $('input[name="survey[survey_type]"]:checked');
    if ($survey_type.val() == TYPE.survey.two) {
      $('#note_question').html('〇・・・空いている ×・・・空いていない<br/>');
    } else if ($survey_type.val() == TYPE.survey.three) {
      $('#note_question').html('〇・・・空いている ×・・・空いていない ▲・・・相談による<br/>');
    }

    var checkbox = $('.kinton-box:checked')
    if (['box3', 'box4'].includes(checkbox.val())) {
      $('#note_question').html('')
    }
  },

  init_common: target => {
    const TYPE = App.surveys.type;
    var $delivery_type = $(`input[name="${target}[delivery_type]"]:checked`)
    if ($delivery_type.val() == TYPE.delivery.custom) {
      $('#delivery_type_option_1').show();
      $('#delivery_type_option_2').hide();
    } else if ($delivery_type.val() == TYPE.delivery.periodic) {
      $('#delivery_type_option_1').hide();
      $('#delivery_type_option_2').show();
    }

    var $delivery_periodic = $('.delivery-periodic:checked');
    if ($delivery_periodic.val() == TYPE.delivery_periodic.specified_day) {
      $('#day_of_week').attr('disabled', false);
    } else {
      $('#day_of_week').attr('disabled', true);
    }
    if ([TYPE.delivery_periodic.every_day, TYPE.delivery_periodic.specified_day].includes($delivery_periodic.val())) {
      $('#delivery_periodic_time').removeClass('hidden');
    } else {
      $('#delivery_periodic_time').addClass('hidden');
    }

    var $destination_type = $('input[name="destination_type"]:checked');
    if ($destination_type.val() == TYPE.destination.all) {
      $('#list_users').hide();
      $('#list_groups').hide();
      $('#destination_type_2_content').hide();
      $('#destination_type_3_content').hide();
    } else if ($destination_type.val() == TYPE.destination.users) {
      $('#list_users').show();
      $('#list_groups').hide();
      $('#destination_type_2_content').show();
      $('#destination_type_3_content').hide();
    } else if ($destination_type.val() == TYPE.destination.group) {
      $('#list_users').hide();
      $('#list_groups').show();
      $('#destination_type_2_content').hide();
      $('#destination_type_3_content').show();
    }
  },

  cocoon_after_insert: () => {
    $('#delivery_type_option_1').on('cocoon:after-insert', (_e, insertedItem, _originalEvent) => {
      App.surveys.check_to_hide_or_show_add_link();
      App.surveys.update_after_insert(insertedItem);
    });
  },

  cocoon_after_remove: () => {
    $('#delivery_type_option_1').on('cocoon:after-remove', () => {
      App.surveys.check_to_hide_or_show_add_link();
      App.surveys.update_after_remove();
    });
  },

  delivery_custom_change: () => {
    $('#delivery_type_option_1').on('change', '.date-value', () => {
      App.surveys.update_after_change_date();
    });
  },

  delivery_periodic_change: () => {
    const TYPE = App.surveys.type;
    $('.delivery-periodic').on('change', (e) => {
      $this = $(e.currentTarget);
      if ($this.val() == TYPE.delivery_periodic.specified_day) {
        $('#day_of_week').attr('disabled', false);
      } else {
        $('#day_of_week').attr('disabled', true);
      }

      if ([TYPE.delivery_periodic.every_day, TYPE.delivery_periodic.specified_day].includes($this.val())) {
        $('#delivery_periodic_time').removeClass('hidden');
      } else {
        $('#delivery_periodic_time').addClass('hidden');
      }
    });
  },

  delivery_type_change: target => {
    const TYPE = App.surveys.type;
    $(`input[name="${target}[delivery_type]"]`).on('change', (e) => {
      $this = $(e.currentTarget);
      if ($this.val() == TYPE.delivery.custom) {
        $('#delivery_type_option_1').show();
        $('#delivery_type_option_2').hide();
      } else if ($this.val() == TYPE.delivery.periodic) {
        $('#delivery_type_option_1').hide();
        $('#delivery_type_option_2').show();
      }
    });
  },

  destination_type_change: () => {
    const TYPE = App.surveys.type;
    $('input[name="destination_type"]').on('change', e => {
      $this = $(e.currentTarget);
      if ($this.val() == TYPE.destination.all) {
        $('#list_users').hide();
        $('#list_groups').hide();
        $('#destination_type_2_content').hide();
        $('#destination_type_3_content').hide();
      } else if ($this.val() == TYPE.destination.users) {
        $('#list_users').show();
        $('#list_groups').hide();
        $('#destination_type_2_content').show();
        $('#destination_type_3_content').hide();
      } else if ($this.val() == TYPE.destination.group) {
        $('#list_users').hide();
        $('#list_groups').show();
        $('#destination_type_2_content').hide();
        $('#destination_type_3_content').show();
      }
    });
  },

  choose_users_or_groups: () => {
    $('#list_users_ajax').on('change', '.organizations', e => {
      $(e.currentTarget).parent().find('li').each((_index, element) => {
        $user_checked = $($(element).find('.user-checked').get(0));
        App.surveys.add_user_to_users_checked($user_checked);
      });
    });

    $('#list_users_ajax').on('change', '.user-checked', (e) => {
      App.surveys.add_user_to_users_checked($(e.currentTarget));
    });

    $('#list_groups_ajax').on('change', '.channel-ids', e => {
      var $element = $(e.currentTarget);
      var $groups_checked = $('#groups_checked');
      var channel_id = $element.val();
      var group_name = $.trim($element.next().html());
      if ($element.is(':checked')) {
        if (channel_id === '') {
          $groups_checked.append(`
            <li data-li-check="${group_name}">
              <input type='hidden' name='group_checked_name[]' id='' value='${group_name}' />
              <span>${group_name}</span><br />
              <span>チャンネルID: <input type="text" class="form-control" style="width: 60%; display: inherit;" name="group_checked[]" id="" value="" /></span>
            </li>
          `);
        } else {
          $groups_checked.append(`
            <li data-li-check="${group_name}">
              <input type="hidden" name="group_checked[]" id="" value="${channel_id}" />
              <input type="hidden" name="group_checked_name[]" id="" value="${group_name}" />
              <span>${group_name}</span><br />
              <span>チャンネルID: ${channel_id}</span>
            </li>
          `);
        }
      } else {
        $(`li[data-li-check="${group_name}"]`).remove();
      }
      count_groups = $groups_checked.find('li').length;
      if (count_groups > 0) {
        $('#count_groups').html(`${count_groups}グループを選択しました。`);
      } else {
        $('#count_groups').html('');
      }
    });
  },

  add_user_to_users_checked: element => {
    var $users_checked = $('#users_checked');
    var text = $.trim(element.parent().find('span').html());
    if (element.is(':checked')) {
      if ($users_checked.find(`input[value="${element.val()}"]`).length === 0) {
        $users_checked.append(`
          <li>
            <input type="hidden" name="checked[]" value="${element.val()}" />
            <input type="hidden" name="checked_name[]" value="${text}" />
            ${text}
          </li>
        `);
      }
    } else {
      $(`input[name="checked[]"][value="${element.val()}"]`).parent().remove();
    }
    count_users = $users_checked.find('li').length;
    if (count_users > 0) {
      $('#count_users').html(`${count_users}ユーザーを選択しました。`);
    } else {
      $('#count_users').html('');
    }
  },

  scroll_users_or_groups: () => {
    // $('#list_users_ajax').on('scroll', (e) => {
    //   element = e.currentTarget;
    //   scrollTop = element.scrollTop;
    //   height = $(element).height();
    //   scrollHeight = element.scrollHeight;
    //   if (scrollTop + height == scrollHeight) {
    //     $.ajax ({
    //       url: '/xhr/users/load',
    //       method: 'POST',
    //       data: {
    //         'offset': $('#list_users_ajax').find('li').length,
    //         'search_name': $('#form_search_user_name').val(),
    //         'searched_user': $('#searched_user').val(),
    //         'user_ids_exist': App.surveys.get_user_ids_exist()
    //       }
    //     })
    //   }
    // });

    $('#list_groups_ajax').on('scroll', (e) => {
      element = e.currentTarget;
      scrollTop = element.scrollTop;
      height = $(element).height();
      scrollHeight = element.scrollHeight;
      if (scrollTop + height == scrollHeight) {
        $.ajax ({
          url: '/xhr/groups/load',
          method: 'POST',
          data: {
            'offset': $('#list_groups_ajax').find('li').length,
            'search_name': $('#form_search_group_name').val(),
            'searched_group': $('#searched_group').val(),
            'group_names_exist': App.surveys.get_group_names_exist()
          }
        })
      }
    });
  },

  form_search_users_submit: () => {
    $('#form_search_user').submit(() => {
      var user_id_exist = App.surveys.get_user_ids_exist();
      $('#user_ids_exist').val(user_id_exist);
    });
  },

  form_search_groups_submit: () => {
    $('#form_search_group').submit(() => {
      var group_names_exist = App.surveys.get_group_names_exist();
      $('#group_names_exist').val(group_names_exist);
    });
  },

  handle_modal_create: () => {
    $('#modal_create #btn_create').on('click', e => {
      var errors = "";
      var $this = $(e.currentTarget);
      var $name = $('#modal_create #name');
      var $channel_id = $('#modal_create #channel_id');
      var $flash_message = $('#modal_create #modal_errors .modal-flash-message');
      var data_target = $this.data('target');
      // validate name
      if ($name.val() === '') {
        errors += "<li>名前を空白のままにすることはできません</li>";
      }

      // validate channel id
      if ($channel_id.val() === '') {
        errors += "<li>チャンネルIDを空白のままにすることはできません</li>";
      }

      if (errors !== '') {
        $flash_message.html(errors).show();
        setTimeout(function () {
          $flash_message.html('').hide();
        }, 3000);
      } else {
        $flash_message.html('').hide();
        var $html_target = `
          <li>
            <input type="hidden" name="add_${data_target}_checked[]" value="${$channel_id.val()}" />
            <input type="hidden" name="add_${data_target}_checked_name[]" value="${$name.val()}" />
            <div class="d-flex align-items-center">
              <span>
                ${$name.val()}<br />
                チャンネルID: ${$channel_id.val()}
              </span>
              <button type="button" class="btn-close ms-3" aria-label="Close"></button>
            </div>
          </li>
        `;
        $(`#${data_target}s_checked`).prepend($html_target);
        $('#modal_create').modal('hide');
      }
    });

    $('#modal_create').on('show.bs.modal', e => {
      $('#modal_create #name').val('');
      $('#modal_create #channel_id').val('');
      var $button = $(e.relatedTarget);
      if ($button.data('target') === 'user') {
        $('#modal_create .modal-title').html('新しいユーザーを作成する');
      } else {
        $('#modal_create .modal-title').html('新しいグループを作成する');
      }
      $('#modal_create #btn_create').data('target', $button.data('target'));
    });

    $('#users_checked, #destination_type_3_content').on('click', '.btn-close', e => {
      $this = $(e.currentTarget);
      $this.closest('li').remove();
    });
  },

  common: () => {
    const TYPE = App.surveys.type;
    App.surveys.cocoon_after_insert();
    App.surveys.cocoon_after_remove();
    App.surveys.delivery_custom_change();
    App.surveys.delivery_type_change('survey');
    App.surveys.delivery_periodic_change();
    App.surveys.destination_type_change();
    App.surveys.choose_users_or_groups();
    App.surveys.scroll_users_or_groups();
    App.surveys.form_search_users_submit();
    App.surveys.form_search_groups_submit();
    App.surveys.handle_modal_create();

    $('input[name="survey[survey_type]"]').on('change', (e) => {
      var kinton = $('.kinton-box:checked');
      $this = $(e.currentTarget);
      if ($this.val() == TYPE.survey.two) {
        if(['box1','box2'].includes(kinton.val())){
          $('#note_question').html('〇・・・空いている ×・・・空いていない<br/>');
        }
        else if(['box3','box4'].includes(kinton.val())){
          $('#note_question').html('')
        }
      } else if ($this.val() == TYPE.survey.three) {
        if(['box1','box2'].includes(kinton.val())){
          $('#note_question').html('〇・・・空いている ×・・・空いていない ▲・・・相談による<br/>');
        }
        else if(['box3','box4'].includes(kinton.val())){
          $('#note_question').html('')
        }
      }
    });

    $('.kinton-box').on('change', e => {
      if (['box3', 'box4'].includes($(e.currentTarget).val()) ){
        $('#note_question').html('')
      }
      else if(['box1','box2'].includes($(e.currentTarget).val())){
        var $survey_type = $('input[name="survey[survey_type]"]:checked');
        if ($survey_type.val() === TYPE.survey.two) {
          $('#note_question').html('〇・・・空いている ×・・・空いていない<br/>');
        } else if ($survey_type.val() === TYPE.survey.three) {
          $('#note_question').html('〇・・・空いている ×・・・空いていない ▲・・・相談による<br/>');
        }
      }
    });
  },

  update_checked_user_action_create: () => {
    $('#users_checked').find('input[name="checked[]"]').each((_index,element) => {
      var value = $(element).val();
      $('#list_users').find(`input[value="${value}"]`).prop('checked', true);
    });
  },

  update_checked_group_action_create: () => {
    $('#groups_checked').find('input[name="group_checked_name[]"]').each((_index, element) => {
      var value = $(element).val();
      $('#list_groups').find($(`span:contains('${value}')`)).prev().prop('checked', true);
    });
  },

  get_user_ids_exist: () => {
    var user_id_exist = [];
    var $users_checked = $('#users_checked');
    $users_checked.find('li').each((_index, element) => {
      user_id_exist.push($(element).find('input').get(0).value);
    });

    return user_id_exist;
  },

  get_group_names_exist: () => {
    var group_names_exist = [];
    var $groups_checked = $('#groups_checked');
    $groups_checked.find('li').each((_index, element) => {
      group_names_exist.push($(element).data('li-check'));
    });

    return group_names_exist;
  },

  sort_index_label: () => {
    var $index_label = $('.index-label');
    $.each($index_label, (index, element) => {
      $(element).html(`${index + 1}回目`);
    });
  },

  set_tooltip: element => {
    $(`${element} tr`).not(':first').tooltip();
  },
};
