App.questions = {
  index: () => {
    $('.service-type').on('click', e => {
      var $this = $(e.currentTarget);
      $('.service-type.btn-primary').removeClass('btn-primary').addClass('btn-light');
      $this.removeClass('btn-light').addClass('btn-primary');
      $.ajax({
        url: '/xhr/questions/change_service',
        method: 'POST',
        data: {service_type: $this.data('service-type')}
      })
    });

    $('#modal_edit_question').on('show.bs.modal', e => {
      var $button = $(e.relatedTarget);
      var content = $(`td.content-${$button.data('question-id')}`).html().replaceAll("<br>", "\n");
      $('#btn_edit_question').data('question-id', $button.data('question-id'));
      $('#modal_edit_question').find('textarea').val(content);
    });

    $('#btn_edit_question').on('click', e => {
      var $this = $(e.currentTarget);
      var content = $.trim($('#modal_edit_question').find('textarea').val());
      if (content === '') {
        var $flash_message =  $('#modal_edit_question #error_message');
       $flash_message.html('内容を空白のままにすることはできません').show();
        setTimeout(() => {
          $flash_message.html('').hide();
        }, 3000);
      } else {
        $.ajax({
          url: '/xhr/questions/update',
          method: 'POST',
          data: {id: $this.data('question-id'), content: content}
        });
      }
    });
  }
}