// Variable App is global
App = {};

App.concerns = {
  tree_view: (element, icon_item, icon_classes, open_flg = false) => {
    var openedClass = 'bi-dash';
    var closedClass = 'bi-plus';

    if (icon_classes) {
      if (icon_classes.openedClass) {
        openedClass = icon_classes.openedClass;
      }
      if (icon_classes.closedClass) {
        closedClass = icon_classes.closedClass;
      }
    }

    // initialize each of the top levels
    const tree = $(element);
    tree.addClass("tree");

    // update icon
    if (icon_item) {
      tree.find('li').each((_index, element) => {
        var $element = $(element);
        if ($element.data('person')) {
          $element.find('input[type="checkbox"]:first').after(`<i style="margin-left: 10px; margin-right: 10px;" class="bi bi-person"></i>`);
        } else {
          $element.find('input[type="checkbox"]:first').after(`<i style="margin-left: 10px; margin-right: 10px;" class="bi ${icon_item}"></i>`);
        }
      });
    }

    // even click for li has ul
    tree.find('li').has('ul').each((_index, element) => {
      var branch = $(element);
      branch.find('input[type="checkbox"]:first').after(`<i style="margin-left: 10px;" class="bi ${closedClass}"></i>`);
      branch.addClass('branch');
      branch.on('click', e => {
        if (e.target == e.currentTarget) {
          current_branch = $(e.currentTarget);
          var icon = current_branch.children('i:first');
          icon.toggleClass(`${openedClass} ${closedClass}`);
          current_branch.children().children().toggle();
        }
      });
      if (!open_flg) {
        branch.children().children().toggle();
      } else {
        icon = branch.children('i:first');
        icon.toggleClass(`${openedClass} ${closedClass}`);
      }
    });

    tree.find('.branch .bi').each((_index, element) => {
      $this = $(element);
      $this.on('click', e => {
        $(e.currentTarget).closest('li').click();
      });
    });

    // handle click checkbox
    $('li :checkbox').on('click', e => {
      var $chk = $(e.currentTarget),
        $li = $chk.closest('li');
        // $ul, $parent;
      if ($li.has('ul')) {
        $li.find(':checkbox').not(e.currentTarget).prop('checked', e.currentTarget.checked)
      }
      // using when click child tick parent
      // do {
      //   $ul = $li.parent();
      //   $parent = $ul.siblings(':checkbox');
      //   if ($chk.is(':checked')) {
      //     $parent.prop('checked', true)
      //   }else{
      //     if($ul.has(':checked').length==0){
      //       $parent.prop('checked', false)
      //     }
      //   }
      //   $chk = $parent;
      //   $li = $chk.closest('li');
      // } while ($ul.is(`:not(${element})`));
    });
  }
};