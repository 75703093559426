import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2/dist/js/select2'

$(document).ready(function() {
  $('.select2').select2({
    width: '300px'
  });
});

