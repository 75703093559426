/* eslint no-console:0 */
import Rails from '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"
import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
require('@nathanvda/cocoon')

import 'stylesheets/base'
import 'init'
import 'navbar.js'
import 'flash_message'
import '../common'
import 'surveys'
import 'broadcasts'
import 'questions'

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// slpit follow controller and action
$(document).ready(() => {
  var controller = $('body').data('controller');
  var action = $('body').data('action');
  App[controller][action]();
});

Rails.start()
ActiveStorage.start()
