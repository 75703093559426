App.broadcasts = {
  index: () => {
    $('.cancel-broadcast').on('click', e => {
      if (confirm('キャンセルしますか？') == true) {
        $this = $(e.currentTarget);
        $.ajax({
          url: '/xhr/broadcasts/cancel',
          method: 'POST',
          data: {'id': $this.data('id')}
        });
      }
    });

    App.surveys.set_tooltip('#list_broadcasts');
  },

  new: () => {
    App.broadcasts.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.broadcasts.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
  },

  create: () => {
    App.broadcasts.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.broadcasts.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
    App.surveys.update_checked_user_action_create();
    App.surveys.update_checked_group_action_create();
    App.surveys.sort_index_label();
  },

  edit: () => {
    // delivery_type
    var delivery_type = $('#delivery_type_option_2').data('delivery-periodic');
    if (Array.isArray(delivery_type)) {
      $(`input[name="broadcast_schedules[delivery_periodic]"][value="3"]`).prop('checked', true);
      $('#day_of_week').val(delivery_type).trigger('change');
    } else {
      $(`input[name="broadcast_schedules[delivery_periodic]"][value="${delivery_type}"]`).prop('checked', true);
    }
    // destination_type
    var destination_type = $('#div_destination_type').data('destination');
    $(`input[name="destination_type"][value="${destination_type}"]`).prop('checked', true);

    App.broadcasts.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.broadcasts.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
    App.surveys.sort_index_label();
  },

  update: () => {
    // delivery_type
    var delivery_type = $('#delivery_type_option_2').data('delivery-periodic');
    if (Array.isArray(delivery_type)) {
      $(`input[name="broadcast_schedules[delivery_periodic]"][value="3"]`).prop('checked', true);
      $('#day_of_week').val(delivery_type).trigger('change');
    } else {
      $(`input[name="broadcast_schedules[delivery_periodic]"][value="${delivery_type}"]`).prop('checked', true);
    }
    // destination_type
    var destination_type = $('#div_destination_type').data('destination');
    $(`input[name="destination_type"][value="${destination_type}"]`).prop('checked', true);

    App.broadcasts.init();
    App.surveys.check_to_hide_or_show_add_link();
    App.broadcasts.common();
    $('#list_users_ajax').find('ul:first').attr('id', 'tree1');
    App.concerns.tree_view('#tree1', 'bi-people', {openedClass: 'bi-chevron-down', closedClass: 'bi-chevron-right'});
    App.surveys.update_checked_user_action_create();
    App.surveys.update_checked_group_action_create();
    App.surveys.sort_index_label();
  },

  histories: () => {
    var list_receiver = $('#list_receiver');
    list_receiver.on('show.bs.modal', e => {
      var button = $(e.relatedTarget);
      var users = button.data('users');
      var $list_users = list_receiver.find('#list_users');
      $list_users.html(users);
    })
    .on('hide.bs.modal', () => {
      var $list_users = list_receiver.find('#list_users');
      $list_users.html('');
    });
  },

  init: () => {
    App.surveys.init_common('broadcast');
  },

  common: () => {
    App.surveys.cocoon_after_insert();
    App.surveys.cocoon_after_remove();
    App.surveys.delivery_custom_change();
    App.surveys.delivery_type_change('broadcast');
    App.surveys.delivery_periodic_change();
    App.surveys.destination_type_change();
    App.surveys.choose_users_or_groups();
    App.surveys.scroll_users_or_groups();
    App.surveys.form_search_users_submit();
    App.surveys.form_search_groups_submit();
    App.surveys.handle_modal_create();
  },
}